<template>
  <div :class="item.classes">
    <a :href="item.link.url" :target="item.link_internal ? '' : '_blank'">
      <img :src="item.image.filename" :alt="item.image.alt" />
    </a>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import { trimWhitespace } from 'theme/filters/trim-whitespace'

export default {
  name: 'ImageWithLink',
  extends: Blok,
  components: {
  },
  computed: {
  },
  filters: {
    trimWhitespace
  }
}
</script>

<style>
</style>
